<template>
	<Modal
		v-bind="{ ...$props, ...$attrs, modalClass }"
		:hide-x="isSuccess"
		@hidden="handleHidden"
		@prevent-dismiss="handlePreventDismiss"
	>
		<div ref="content" class="content-sizer">
			<Fade :duration="100">
				<div v-show="!isSuccess" class="base-content">
					<slot />
				</div>
			</Fade>
			<Fade :delay="300">
				<div v-show="isSuccess" ref="success" class="success-content">
					<slot name="success" />
				</div>
			</Fade>
		</div>
	</Modal>
</template>

<script>
import Fade from '@/components/transitions/Fade.vue';

export default {
	name: 'BaseModal',
	components: { Fade },
	inject: ['$modals'],
	props: {
		name: {
			type: String,
			required: true
		}
	},
	emits: ['hidden'],
	data() {
		return {
			isSuccess: undefined,
			successDuration: 2000
		};
	},
	computed: {
		modalClass() {
			return (
				'base-modal ' +
				(this.isSuccess
					? 'modal-success' + (this.$attrs['modal-class'] ? ' ' + this.$attrs['modal-class'] : '')
					: this.$attrs['modal-class'])
			);
		}
	},
	methods: {
		handlePreventDismiss() {
			// if a modal dismiss was prevented in a success state
			// override it and force the modal to close
			if (this.isSuccess) {
				this.$modals.hide(this.name);
			}
		},
		handleHidden() {
			this.resetState();
			this.$emit('hidden');
		},
		showSuccess() {
			this.$refs.content.style.width = this.$refs.content.offsetWidth + 'px';
			this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px';
			this.isSuccess = true;
			this.$nextTick(() => {
				if (this.$refs.success) {
					this.$refs.success.style.width = this.$refs.success.offsetWidth + 2 + 'px';
					this.$refs.content.style.width = this.$refs.success.offsetWidth + 2 + 'px';
					this.$refs.content.style.height = this.$refs.success.offsetHeight + 'px';
					this.$refs.success.style.marginTop = '0px';
				}
			});
			return new Promise((resolve) => {
				setTimeout(() => {
					this.$modals.hide(this.name);
					resolve();
				}, this.successDuration);
			});
		},
		resetState() {
			this.$refs.content.style.width = null;
			this.$refs.content.style.height = null;
			this.$refs.success.style.marginTop = null;
			this.$refs.success.style.width = null;
			this.isSuccess = undefined;
		}
	}
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.base-modal {
	.modal-dialog {
		.modal-content {
			border-radius: rem(8);
			border: none;
			@include small-elevation;

			.modal-body {
				padding: rem(24);

				.content-sizer {
					position: relative;
					transition: height 0.3s 0.2s, width 0.3s 0.2s;
				}
				.success-content {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					display: inline-block;
					text-align: center;
					margin-top: rem(44);
					transition: margin-top 0.3s 0.2s;
				}
				.loader-overlay {
					border-radius: rem(8);
				}
				.loader-animation {
					margin-top: rem(40);
				}
			}
		}
	}
	&.modal-success {
		text-align: center;

		.modal-dialog {
			display: inline-flex;
			width: auto;

			// prevent shake when in success state
			&.shake {
				animation: none !important;
			}
			// hide close button when in success state
			.modal-close {
				display: none;
			}
		}
	}
}
</style>
