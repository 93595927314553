import { defineAsyncComponent } from 'vue';
import { defineStore } from 'pinia';
import { has } from 'lodash';
const Loader = defineAsyncComponent(() => import('./components/Loader.vue'));
const Button = defineAsyncComponent(() => import('./components/Button.vue'));
const FormFieldBase = defineAsyncComponent(() => import('./components/fields/FormFieldBase.vue'));
const TextInput = defineAsyncComponent(() => import('./components/fields/TextInput.vue'));
const MultiLineTextInput = defineAsyncComponent(() => import('./components/fields/MultiLineTextInput.vue'));
const NumberInput = defineAsyncComponent(() => import('./components/fields/NumberInput.vue'));
const PasswordInput = defineAsyncComponent(() => import('./components/fields/PasswordInput.vue'));
const EmailInput = defineAsyncComponent(() => import('./components/fields/EmailInput.vue'));
const SelectInput = defineAsyncComponent(() => import('./components/fields/SelectInput.vue'));
const MultiSelectInput = defineAsyncComponent(() => import('./components/fields/MultiSelectInput.vue'));
const RadiosInput = defineAsyncComponent(() => import('./components/fields/RadiosInput.vue'));
const YesNoInput = defineAsyncComponent(() => import('./components/fields/YesNoInput.vue'));
const CheckboxesInput = defineAsyncComponent(() => import('./components/fields/CheckboxesInput.vue'));
const SingleCheckboxInput = defineAsyncComponent(() => import('./components/fields/SingleCheckboxInput.vue'));
const FileInput = defineAsyncComponent(() => import('./components/fields/FileInput.vue'));
const ImageFileInput = defineAsyncComponent(() => import('./components/fields/ImageFileInput.vue'));
const AudioFileInput = defineAsyncComponent(() => import('./components/fields/AudioFileInput.vue'));
const VideoFileInput = defineAsyncComponent(() => import('./components/fields/VideoFileInput.vue'));
const PdfFileInput = defineAsyncComponent(() => import('./components/fields/PdfFileInput.vue'));
const TagInput = defineAsyncComponent(() => import('./components/fields/TagInput.vue'));
import formDataMixin from './mixins/formData';
import baseFieldMixin from './mixins/baseField';
import textFieldMixin from './mixins/textField';
import optionsFieldMixin from './mixins/optionsField';

// Form Plugin
const PLUGIN_NAME = 'FormPlugin';
const VERSION = '1.0.0';

const DEFAULT_OPTIONS = {
	buttonComponent: defineAsyncComponent(() => import('./components/Button.vue')),
	primaryButtonProps: {},
	secondaryButtonProps: { color: 'light' },
	customComponents: {}
};

let useStore;
const usePluginStore = () => {
	if (typeof useStore !== 'function') throw new Error(PLUGIN_NAME + ' Store not setup');
	return useStore();
};

const FormPlugin = {
	install(app, userOptions) {
		const options = { ...DEFAULT_OPTIONS, ...userOptions };

		// set up plugins registry if it doesn't exist
		if (!has(app.config.globalProperties, '$plugins')) {
			app.config.globalProperties.$plugins = {};
		}
		// register plugin with plugins registry
		app.config.globalProperties.$plugins[PLUGIN_NAME] = VERSION;

		useStore = defineStore(PLUGIN_NAME, {
			state: () => {
				return {
					buttonComponent: options.buttonComponent,
					primaryButtonProps: options.primaryButtonProps,
					secondaryButtonProps: options.secondaryButtonProps,
					submitButtonProps: { ...options.primaryButtonProps, type: 'submit' },
					cancelButtonProps: { ...options.secondaryButtonProps }
				};
			}
		});

		// register 'Form' component globally
		app.component(
			'Form',
			defineAsyncComponent(async () => {
				const { default: Form } = await import('./components/Form.vue');
				// register any custom components local to the Form
				Form.components = { ...options.customComponents, ...Form.components };
				Form.components.Button = options.buttonComponent;
				return Form;
			})
		);
		app.component(
			'FormField',
			defineAsyncComponent(async () => {
				const { default: FormField } = await import('./components/FormField.vue');
				// register any custom components local to the Form
				FormField.components = { ...options.customComponents, ...FormField.components };
				return FormField;
			})
		);
	}
};
export {
	FormPlugin as default,
	usePluginStore,
	Loader,
	Button,
	FormFieldBase,
	TextInput,
	MultiLineTextInput,
	NumberInput,
	PasswordInput,
	EmailInput,
	SelectInput,
	MultiSelectInput,
	RadiosInput,
	YesNoInput,
	CheckboxesInput,
	SingleCheckboxInput,
	FileInput,
	ImageFileInput,
	AudioFileInput,
	VideoFileInput,
	PdfFileInput,
	TagInput,
	formDataMixin,
	baseFieldMixin,
	textFieldMixin,
	optionsFieldMixin
};
