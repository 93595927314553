import { getServiceStore } from '@/plugins/FeathersAPI';

export default async function hasPermissions({ to, next }) {
	const user = getServiceStore('auth').user;
	const requiredPermissions = typeof to.meta.permissions == 'function' ? to.meta.permissions(to) : [];
	const activePermissions = (user && user.activePermissionsList) || [];

	const accessActive =
		requiredPermissions.length == 0 || requiredPermissions.some((permission) => activePermissions.includes(permission));

	// has access - continue
	if (accessActive) return next();

	// fetch pending and deactivated permissions
	await getServiceStore('users').get(user.id, {
		query: { $include: ['pendingLicenses', { association: 'deactivatedLicenses', $sort: { createdAt: -1 } }] }
	});
	const updatedUser = getServiceStore('users').getFromStore(user.id);

	const pendingPermissions = updatedUser.pendingLicenses.reduce((result, license) => {
		return license.licenseType.role.permissions
			.map((p) => p.name)
			.reduce((r, p) => {
				return r.includes(p) ? result : [...result, p];
			}, result);
	}, []);
	const accessPending =
		requiredPermissions.length == 0 ||
		requiredPermissions.some((permission) => pendingPermissions.includes(permission));

	// has pending access - needs payment
	if (accessPending) return next('/payment');

	const deactivatedPermissions = updatedUser.deactivatedLicenses.reduce((result, license) => {
		return license.licenseType.role.permissions
			.map((p) => p.name)
			.reduce((r, p) => {
				return r.includes(p) ? result : [...result, p];
			}, result);
	}, []);
	const accessDeactivated =
		requiredPermissions.length == 0 ||
		requiredPermissions.some((permission) => deactivatedPermissions.includes(permission));

	// has deactivated access
	if (accessDeactivated) {
		// if most recent deactivated license is a clinician license and does not have a parent
		// then we know they are a non enterprise clinician
		const isNonEnterpriseClinician =
			updatedUser.deactivatedLicenses[0].licenseType.name == 'clinician' &&
			updatedUser.deactivatedLicenses[0].parentLicenseId == null;
		// redirect to the payment page so they get the option to resubscribe
		if (isNonEnterpriseClinician) return next('/payment');

		// this is an enterprise user so redirect to the no access page
		// org-managers - can only pay through a quote by contacting us
		// org-clinicians - cant pay, as their license is controlled by their manager
		return next('/no-access?to=' + to.fullPath);
	}
	// no access
	return next('/404');
}
