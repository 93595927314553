import { getServiceStore } from '@/plugins/FeathersAPI';

export default {
	computed: {
		serviceName() {
			return this.$route.params.service;
		},
		hasReadPermissions() {
			return this.serviceName
				? this.hasPermissions(['admin-' + this.serviceName, 'admin-' + this.serviceName + '-read'])
				: false;
		},
		hasEditPermissions() {
			return this.serviceName
				? this.hasPermissions(['admin-' + this.serviceName, 'admin-' + this.serviceName + '-edit'])
				: false;
		},
		hasDeletePermissions() {
			return this.serviceName
				? this.hasPermissions(['admin-' + this.serviceName, 'admin-' + this.serviceName + '-delete'])
				: false;
		},
		hasImportPermissions() {
			return this.serviceName ? this.hasPermissions(['admin-' + this.serviceName + '-import']) : false;
		},
		hasRevisionPermissions() {
			return this.serviceName
				? this.hasPermissions(['admin-' + this.serviceName, 'admin-' + this.serviceName + '-revision'])
				: false;
		},
		hasRevisionRestorePermissions() {
			return this.serviceName
				? this.hasPermissions(['admin-' + this.serviceName, 'admin-' + this.serviceName + '-revision-restore'])
				: false;
		}
	},
	methods: {
		hasPermissions(requiredPermissions = []) {
			const user = getServiceStore('auth').user;
			const userPermissions = (user && user.fullPermissionsList) || [];
			const accessGranted =
				requiredPermissions.length == 0 ||
				requiredPermissions.some((permission) => userPermissions.includes(permission));
			return accessGranted;
		},
		hasStatusEditPermissions(hierarchy) {
			return this.hasPermissions(['status-edit-' + hierarchy, 'status-edit-' + this.serviceName + '-' + hierarchy]);
		}
	}
};
