import { getServiceStore } from '@/plugins/FeathersAPI';
import { usePluginStore } from '../../plugins/Authentication';

export default function isAuthenticated({ to, next }) {
	const authPluginStore = usePluginStore();
	if (!getServiceStore('auth').isAuthenticated) {
		authPluginStore.loginRedirectRoute = to.fullPath;
		return next({
			path: authPluginStore.getRoute('login')
		});
	}

	return next();
}
