import { cloneDeep, debounce, has, isEqual } from 'lodash';

import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
// import { iff, discard } from 'feathers-hooks-common';
import { createPinia, defineStore, storeToRefs, mapActions } from 'pinia';
import { setupFeathersPinia, defineAuthStore, useFind } from 'feathers-pinia';

import loadToStore from './mixins/loadToStore';
import { errorHandler } from '@/error-handler';

// Authentication Plugin
const PLUGIN_NAME = 'FeathersAPI';
const VERSION = '1.0.0';

const DEFAULT_OPTIONS = {
	apis: [
		// {
		// 	name: 'api',
		// 	url: 'http://localhost:3030',
		// 	idField: 'id',
		// 	whitelist: ['$regex', '$options'],
		// 	services: [
		// 		{
		// 			servicePath: 'users',
		// 			modelName: 'User',
		// 			instanceDefaults: {
		// 				email: '',
		// 				password: ''
		// 			},
		// 			setupInstance: (data, { model }) => {},
		// 			hooks: {}
		// 		}
		// 	],
		// 	authenticationService: 'users'
		// }
	]
};

const stores = {};
const models = {};

const FeathersAPI = {
	install(app, userOptions = {}) {
		const options = { ...DEFAULT_OPTIONS, ...userOptions };

		// set up plugins registry if it doesn't exist
		if (!has(app.config.globalProperties, '$plugins')) {
			app.config.globalProperties.$plugins = {};
		}
		// register plugin with plugins registry
		app.config.globalProperties.$plugins[PLUGIN_NAME] = VERSION;

		const pinia = createPinia();
		app.use(pinia);

		// new function for defining associations to a single instance
		// this is done to ensure instance data is only ever stored in its own service store
		// there is now no longer any nested instances stored in the instance data
		// these properties are non-enumerable to insure the associated instance is never cloned
		const defineSingleAssociation = (data, associationProp, AssociationModel) => {
			const propValue = data[associationProp];
			Object.defineProperty(data, associationProp, {
				enumerable: false,
				get() {
					// if id value is set return its object in store
					if (data['_' + associationProp]) {
						// if id value is temp object get from store temps
						if (typeof data['_' + associationProp] == 'object' && data['_' + associationProp].__tempId)
							return AssociationModel.store.tempsById[data['_' + associationProp].__tempId];
						// else get store from items
						return AssociationModel.store.itemsById[data['_' + associationProp]];
					}
					// else return empty object
					return {};
				},
				set(value) {
					if (value) {
						// if value is an object add/update in store
						if (typeof value == 'object') {
							AssociationModel.store.addToStore(value);
							// set id or full object if no id set
							data['_' + associationProp] = value.id || value;
						}
						// if value is a number and its already in store set id with number
						else if (typeof value == 'number' && AssociationModel.store.getFromStore(value))
							data['_' + associationProp] = value;
					}
					// else set as null
					else data['_' + associationProp] = null;
				}
			});
			if (propValue) data[associationProp] = propValue;
		};
		// new function for defining associations to many instances
		// this is done to ensure instance data is only ever stored in its own service store
		// there is now no longer any nested instances stored in the instance data
		// these properties are non-enumerable to insure the associated instances is never cloned
		// **********
		// the returned value from these associations are arrays, but they can not be mutated, only assigned
		// standard methods like push and splice do not work as expected
		// we must re assign the array value through the '=' operator if it is intended to be modified
		const defineManyAssociation = (data, associationProp, AssociationModel) => {
			const propValue = data[associationProp];
			Object.defineProperty(data, associationProp, {
				enumerable: false,
				get() {
					// if id values are set map them to objects from store
					if (data['_' + associationProp])
						return data['_' + associationProp].map((item) => {
							// if item is temp object get from store temps
							if (typeof item == 'object' && item.__tempId) return AssociationModel.store.tempsById[item.__tempId];
							// else get item from store
							return AssociationModel.store.itemsById[item] || item;
						});
					// if ids are not set return empty array
					return [];
				},
				set(value) {
					data['_' + associationProp] = value.map((item) => {
						if (item) {
							// if item is an object add/update in store
							if (typeof item == 'object') {
								AssociationModel.store.addToStore(item);
								// set id or full object if no id set
								return item.id || item;
							}
							// if item is a number and its already in store just return the number
							else if (typeof item == 'number' && AssociationModel.store.getFromStore(item)) return item;
						} else return null;
					});
				}
			});
			if (propValue) data[associationProp] = propValue;
		};
		const defineInstanceGetter = (data, prop, value) => {
			Object.defineProperty(data, prop, {
				enumerable: false,
				get() {
					return value;
				}
			});
		};

		const createModel = (
			BaseModel,
			serviceModelName,
			modalInstanceDefaults,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
			modalSetupInstance = (data, { models }) => {}
		) => {
			const Model = class extends BaseModel {
				// static name = `${serviceModelName}`;
				constructor(data, options) {
					super(data, options);
					this.init();
				}
				// // Required for $FeathersVuex plugin to work after production transpile.
				// static modelName = `${serviceModelName}`;
				// // Define default properties here
				// static instanceDefaults() {
				// 	return modalInstanceDefaults;
				// }
				static setupInstance(data) {
					return modalSetupInstance(data, {
						models,
						defineSingleAssociation,
						defineManyAssociation,
						defineInstanceGetter
					});
				}
			};
			Object.defineProperty(Model, 'name', { value: serviceModelName });
			return Model;
		};

		for (const api of options.apis) {
			const feathersClient = feathers()
				.configure(
					socketio(io(api.url, { transports: ['websocket'] }), {
						timeout: 10000
					})
				)
				.configure(auth({ storage: window.localStorage }));
			// .hooks({
			// 	before: {
			// 		all: [iff((context) => ['create', 'update', 'patch'].includes(context.method), discard('__id', '__isTemp'))]
			// 	}
			// });
			const DEFAULT_STATE = { $feathersSocket: feathersClient.io };

			// feathersClient.service('exercises').on('patched', (data) => {
			// 	console.log('Socket: exercise patched ', data);
			// });

			const { defineStore: defineFeathersStore, BaseModel } = setupFeathersPinia({
				clients: { [api.name]: feathersClient },
				idField: api.idField
			});
			models[api.name] = {};

			if (api.appHooks) {
				feathersClient.hooks(api.appHooks);
			}

			for (const service of api.services) {
				const { servicePath, modelName, instanceDefaults, setupInstance, hooks, timeout } = service;

				if (modelName === false) {
					stores[servicePath] = defineStore(servicePath, {
						actions: {
							find(params) {
								return feathersClient.service(servicePath).find(params);
							},
							get(id, params) {
								return feathersClient.service(servicePath).get(id, params);
							},
							create(data, params) {
								return feathersClient.service(servicePath).create(data, params);
							},
							patch(id, data, params) {
								return feathersClient.service(servicePath).patch(id, data, params);
							},
							remove(id, params) {
								return feathersClient.service(servicePath).remove(id, params);
							},
							...(service.actions || {})
						},
						state: () => ({
							...DEFAULT_STATE,
							...(service.state || {})
						})
					});
				} else {
					stores[servicePath] = defineFeathersStore({
						servicePath,
						clientAlias: api.name,
						id: servicePath,
						Model: createModel(BaseModel, modelName, instanceDefaults, setupInstance),
						whitelist: service.whitelist || api.whitelist,
						state: () => ({
							...DEFAULT_STATE,
							...(service.state || {})
						}),
						getters: { ...(service.getters || {}) },
						actions: {
							findAll(
								params = {},
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								afterEach = () => {},
								vm = {}
							) {
								return this.find(params)
									.then(async (results) => {
										afterEach(vm, results);
										if (results.total > results.limit + results.skip) {
											const query = params.query || {};
											return this.findAll(
												{
													...params,
													query: { ...query, $skip: results.limit + results.skip }
												},
												afterEach,
												vm
											);
										}
										return results;
									})
									.catch((error) => {
										errorHandler(error);
									});
							},
							...(service.actions || {})
						}
					});
					Object.defineProperty(models[api.name], modelName, {
						get() {
							return stores[servicePath]().Model;
						}
					});
				}
				feathersClient.service(servicePath).hooks(typeof hooks == 'function' ? hooks(getServiceStore) : hooks);
				if (timeout) feathersClient.service(servicePath).timeout = timeout;
				for (const key in service.customEvents) {
					if (Object.hasOwnProperty.call(service.customEvents, key)) {
						const handler = service.customEvents[key];
						feathersClient.service(service.servicePath).on(key, (item) => {
							handler(item, { app, store: stores[servicePath]() });
						});
					}
				}
			}
			if (api.auth) {
				const storeId = api.auth.namespace || 'auth';
				const usersService = api.auth.userService;
				const additionalParams = api.auth.additionalParams;
				const onAuthenticate = api.auth.onAuthenticate;
				const onLogout = api.auth.onLogout;

				const userState = usersService ? { userId: null } : {};
				const userGetters = usersService
					? {
							user() {
								const user = this.userId ? getServiceStore(usersService).getFromStore(this.userId) : null;
								return user;
							}
					  }
					: {};
				const userActions = usersService
					? {
							async authenticate(authData) {
								try {
									const token = this.feathersClient.settings.storage.storage['feathers-jwt'] || this.accessToken;
									if (typeof authData == 'undefined' && !token) return false;

									authData = authData || {
										strategy: 'jwt',
										accessToken: token
									};
									// ensure socket is connected
									// - we need to do this in any code that communicates to the server immediately after page focus
									// - it is possible that while the page was un focused, the socket connection was paused or disconnected
									// - it should reconncet automatically, but we need to await it before continuing
									await getServiceStore('socket').confirmConnection();
									const response = await this.feathersClient.authenticate(authData, additionalParams);
									const { accessToken, authentication, user } = response;
									getServiceStore(usersService).addToStore(user);
									const isReauthenticated = this.isAuthenticated;
									Object.assign(this, {
										accessToken,
										authentication,
										userId: user.id || user._id,
										isAuthenticated: true
									});

									if (onAuthenticate) {
										onAuthenticate({ user, isReauthenticated });
									}

									return response;
								} catch (error) {
									error.data = error.data || {};
									error.data.strategy = error.hook.data.strategy;
									this.error = error;
									throw error;
								}
							}
					  }
					: {};

				stores[storeId] = defineAuthStore({
					feathersClient,
					id: storeId,
					state: () => ({
						...DEFAULT_STATE,
						...(api.auth.state || {}),
						...userState
					}),
					getters: {
						...(api.auth.getters || {}),
						...userGetters
					},
					actions: {
						...(api.auth.actions || {}),
						...userActions,
						logout() {
							if (onLogout) {
								onLogout();
							}
							return feathersClient.logout().then(() => {
								this.accessToken = null;
								this.isAuthenticated = false;
								this.authentication = null;
							});
						}
					}
				});
			}
		}
	}
};

const getServiceStore = (service) => {
	if (typeof stores[service] == 'undefined') throw new Error('Invalid Service Name: ' + service);
	return stores[service]();
};
const mapServiceState = (service, states = []) => {
	const store = getServiceStore(service);
	const storeRefs = storeToRefs(store);
	return Array.isArray(states)
		? states.reduce((reduced, key) => {
				reduced[key] = function () {
					return storeRefs[key].value;
				};
				return reduced;
		  }, {})
		: Object.keys(states).reduce((reduced, key) => {
				reduced[key] = () => {
					const storeKey = states[key];
					return storeRefs[storeKey].value;
				};
				return reduced;
		  }, {});
};
const mapServiceActions = (service, actions = []) => {
	return mapActions(stores[service], actions);
};
// not ready yet, wait for full release of feathers-pinia
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const serviceFind = (service, params, fetchParams) => {
	return useFind({ model: stores[service]().Model, params, fetchParams });
};
// mixin to replace use of feathers-pinia useFind function
const serviceFindMixin = (options) => {
	const service = options.service;
	const name = options.name;
	const serviceName = name || service.replace(/-./g, (x) => x[1].toUpperCase());

	const nameToUse = name ? name : typeof service == 'string' ? serviceName : 'items';
	if (typeof service !== 'function' && typeof stores[service] == 'undefined')
		throw new Error('Invalid Service option: ' + service);
	const params = options.params;
	const fetchParams = options.fetchParams;
	const fetchAll = options.fetchAll;
	const afterEach = options.afterEachFetch;
	const afterFetch = options.afterFetch;
	const debounceTime = options.debounce || 500;
	const capitalizedName = nameToUse.charAt(0).toUpperCase() + nameToUse.slice(1);
	const ITEMS = nameToUse;
	const FETCH = 'fetch' + capitalizedName;
	const DO_FETCH = '_doFetch' + capitalizedName;
	const DO_DEBOUNCED_FETCH = '_doDebouncedFetch' + capitalizedName;
	const IS_FIND_PENDING = 'isFind' + capitalizedName + 'Pending';
	const PARAMS = nameToUse + 'Params';
	const FETCH_PARAMS = nameToUse + 'FetchParams';
	const LATEST_QUERY = nameToUse + 'LatestQuery';
	const PAGINATION_DATA = nameToUse + 'PaginationData';
	const ERROR = nameToUse + 'Error';

	return {
		data() {
			return {
				[IS_FIND_PENDING]: false,
				[LATEST_QUERY]: null,
				[ERROR]: null
			};
		},
		computed: {
			[PARAMS]() {
				return typeof params == 'function' ? params(this) : null;
			},
			[FETCH_PARAMS]() {
				return typeof fetchParams == 'function' ? fetchParams(this) : this[PARAMS];
			},
			[ITEMS]() {
				const localParams = cloneDeep(this[PARAMS]);
				if (localParams && localParams.query) {
					localParams.query = convertSearchProp(localParams.query);
					localParams.query = fixMalformedQuery(localParams.query);
					return stores[typeof service == 'function' ? service(this) : service]().findInStore(localParams).data;
				}
				return [];
			},
			[PAGINATION_DATA]() {
				return stores[typeof service == 'function' ? service(this) : service]().pagination.default || {};
			}
		},
		methods: {
			[FETCH]() {
				this[IS_FIND_PENDING] = true;
				this[DO_DEBOUNCED_FETCH]();
			},
			[DO_FETCH]() {
				const params = this[FETCH_PARAMS];
				if (params) {
					const findMethod = fetchAll ? 'findAll' : 'find';
					const args = fetchAll ? [params, afterEach, this] : [params];
					stores[typeof service == 'function' ? service(this) : service]()
						[findMethod].apply(this, args)
						.then((result) => {
							this[IS_FIND_PENDING] = false;
							this[LATEST_QUERY] = {
								params,
								response: result
							};
							this[ERROR] = null;
							if (typeof afterFetch == 'function') return afterFetch(this, result);
						})
						.catch((err) => {
							this[IS_FIND_PENDING] = false;
							this[ERROR] = err.message;
							errorHandler(err);
						});
				}
			}
		},
		created() {
			this[DO_DEBOUNCED_FETCH] = debounce(this[DO_FETCH], debounceTime);

			this[FETCH]();
		},
		watch: {
			[FETCH_PARAMS](val, oldVal) {
				if (!isEqual(val, oldVal)) this[FETCH]();
			}
		}
	};
};
const serviceGetMixin = (options) => {
	const service = options.service;
	if (typeof stores[service] == 'undefined') throw new Error('Invalid Service Name: ' + service);
	const id = options.id;
	const params = options.params;
	const fetchParams = options.fetchParams;
	const afterFetch = options.afterFetch;
	const debounceTime = options.debounce || 500;
	const serviceStore = stores[service]();
	const serviceSingular = serviceStore.Model.name.charAt(0).toLowerCase() + serviceStore.Model.name.slice(1);
	const capitalizedServiceSingular = serviceSingular.charAt(0).toUpperCase() + serviceSingular.slice(1);
	const FETCH = 'fetch' + capitalizedServiceSingular;
	const DO_FETCH = '_doFetch' + capitalizedServiceSingular;
	const DO_DEBOUNCED_FETCH = '_doDebouncedFetch' + capitalizedServiceSingular;
	const ITEM = serviceSingular;
	const ID = 'serviceItemId';
	const PARAMS = serviceSingular + 'Params';
	const FETCH_PARAMS = serviceSingular + 'FetchParams';
	const IS_GET_PENDING = 'isGet' + capitalizedServiceSingular + 'Pending';
	const ERROR = serviceSingular + 'Error';

	return {
		data() {
			return {
				[IS_GET_PENDING]: false,
				[ERROR]: null
			};
		},
		computed: {
			[ID]() {
				return typeof id == 'function' ? id(this) : typeof id == 'string' ? this[id] : id;
			},
			[PARAMS]() {
				return typeof params == 'function' ? params(this) : null;
			},
			[FETCH_PARAMS]() {
				return typeof fetchParams == 'function' ? fetchParams(this) : this[PARAMS];
			},
			[ITEM]() {
				return this[ID] ? stores[service]().getFromStore(this[ID]) : {};
			}
		},
		methods: {
			[FETCH]() {
				this[IS_GET_PENDING] = true;
				this[DO_DEBOUNCED_FETCH]();
			},
			[DO_FETCH]() {
				const params = this[FETCH_PARAMS];
				stores[service]()
					.get(this[ID], params)
					.then((result) => {
						this[IS_GET_PENDING] = false;
						this[ERROR] = null;
						if (typeof afterFetch == 'function') return afterFetch(this, result);
					})
					.catch((err) => {
						this[IS_GET_PENDING] = false;
						this[ERROR] = err.message;
						errorHandler(err);
					});
			}
		},
		created() {
			this[DO_DEBOUNCED_FETCH] = debounce(this[DO_FETCH], debounceTime);

			if (this[ID]) this[FETCH]();
		},
		watch: {
			[ID](val, oldVal) {
				if (val && val !== oldVal) this[FETCH]();
			},
			[FETCH_PARAMS](val, oldVal) {
				if (!isEqual(val, oldVal)) this[FETCH]();
			}
		}
	};
};

const convertSearchProp = (obj) => {
	for (const prop in obj) {
		if (Object.hasOwnProperty.call(obj, prop)) {
			if (prop == '$search') {
				const isNot = obj[prop].charAt(0) == '!';
				if (isNot) obj.$notILike = obj[prop].substring(1);
				else obj.$iLike = obj[prop];
				delete obj[prop];
			} else if (typeof obj[prop] == 'object') obj[prop] = convertSearchProp(obj[prop]);
		}
	}
	return obj;
};
const fixMalformedQuery = (queryObj, queryProp) => {
	const obj = queryProp ? cloneDeep(queryObj[queryProp]) : cloneDeep(queryObj);
	for (const prop in obj) {
		if (Object.hasOwnProperty.call(obj, prop)) {
			if (prop == '$or') {
				const $orVal = queryProp ? obj.$or.map((val) => ({ [queryProp]: val })) : obj.$or;
				if (typeof queryObj.$or == 'undefined' || !queryProp) queryObj.$or = $orVal;
				else {
					queryObj.$and = queryObj.$and || [];
					queryObj.$and.push({ $or: $orVal });
					if (queryProp) queryObj.$and.push({ $or: queryObj.$or });
					delete queryObj.$or;
				}
				delete queryObj[queryProp];
			} else if (typeof obj[prop] == 'object') {
				const result = fixMalformedQuery(obj, prop);
				if (queryProp) queryObj[queryProp] = result;
				else {
					queryObj = result;
				}
			}
		}
	}
	return queryObj;
};

export {
	FeathersAPI as default,
	getServiceStore,
	mapServiceState,
	mapServiceActions,
	serviceFindMixin,
	serviceGetMixin,
	loadToStore
};
