import { productFruits } from 'product-fruits';
import env from './env';

export const init = (user, roleParam) => {
	const productFruitsWorkspaceId = env('PRODUCT_FRUITS_WORKSPACE_ID');
	if (productFruitsWorkspaceId) {
		try {
			const userInfo = {
				username: user.id,
				firstname: user.profile.firstName,
				signUpAt: user.createdAt
			};

			let role = undefined;
			const hasPatientPermission = user.activePermissionsList?.includes('patient') || roleParam == 'patient';
			const hasManagerPermission = user.activePermissionsList?.includes('org-manager') || roleParam == 'org-manager';
			const hasClinicianPermission = user.activePermissionsList?.includes('clinician') || roleParam == 'clinician';

			// if user is org-manager
			if (hasManagerPermission) {
				// and clinician
				if (hasClinicianPermission) role = 'org-clinician-manager';
				// and not clinician
				else role = 'org-manager';
			}
			// if user is a clinician
			else if (hasClinicianPermission) role = user.profile.clinicianOrganizationId ? 'org-clinician' : 'clinician';
			else if (hasPatientPermission) role = 'patient';

			// include email for all managers, clinicians and tactus team members. Not patients
			if (hasManagerPermission || hasClinicianPermission || user.email.includes('@tactustherapy.com')) {
				userInfo.email = user.email;
			}
			if (role) {
				userInfo.role = role;
				// we should only init PF once, when we have all the necessary user info
				// in our case the user role is important for the PF tours to work properly
				// therefore we only init PF if we have a valid role
				productFruits.init(productFruitsWorkspaceId, 'en', userInfo);
			}
		} catch (error) {
			console.error(`Issue initializing product fruits with workspace id:${productFruitsWorkspaceId}. Error:${error}`);
		}
	} else {
		console.warn(`Missing env variable: 'PRODUCT_FRUITS_WORKSPACE_ID'.`);
	}
};

export const trackPageChanged = () => {
	// https://help.productfruits.com/en/article/installation-via-npm-package
	productFruits.safeExec(($productFruits) => {
		$productFruits.push(['pageChanged']);
	});
};

export const initFeedback = (user) => {
	// Use the product fruits safeExec helper method because the callback is only called when product fruits
	// has been initialized and is ready
	productFruits.safeExec(() => {
		// If we ever want to edit or disable parts of the feedback widget (can also edit the text of different
		// parts of the feedback widget in window.productFruits['feedback'].config), we could do so like this:
		// (there are many customizable parts of the feedback widget that can be edited through their website > Widgets > Feedback
		// please be aware that this way of customizing the feedback widget programmatically isn't actually documented by product fruits and could change.
		// It was something that I found out and thought it might be handy)
		// window.productFruits['feedback'].config.allowScreenshots = false;
		// window.productFruits['feedback'].config.allowVideos = false;

		// disable the whole feedback widget for patients
		const hasPatientPermission = user.fullPermissionsList?.includes('patient');
		if (hasPatientPermission) {
			window.productFruits['feedback'].config.isActive = false;
		}

		// For some reason, the 'native' recorder mode for the feedback widget does not work on mobile devices, nor does it work on Desktop for
		// Safari and Firefox (product fruits has admitted this), but the 'html' mode does work.
		// Therefore we set the recorder mode programmatically based on the device and browser, meaning the we only set the recorder mode to
		// 'native' for Google Chrome on desktop - all other cases we use 'html'.
		// For now we are just checking if the hover style is available in determining if the device is mobile or not.
		// This may not work for every single case as you can connect a mouse to a mobile device which would enable
		// hover or use a desktop computer with touch screen which might disable hover.
		// There are definitely other ways to determine device type, see example:
		// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser/11381730#11381730
		// But the root issue is that we don't know exactly in which cases the native recorder mode is supported and
		// which cases it isn't, otherwise we could just set the recorder mode based on if the mode is supported.

		const isChromeWindowObjectDefined = !!window.chrome;
		const isChromeByUserAgent = /chrome/i.test(navigator.userAgent);
		// this evaluates to true for both Google Chrome and Microsoft Edge because the window.chrome property is defined for Edge
		// and the user agent for Edge also contains a 'Chrome' part, it just has an extra 'edg' part in the string
		// this is okay because 'native' mode actually works on Edge desktop
		const isChrome = isChromeWindowObjectDefined && isChromeByUserAgent;
		// we are treating mobile devices as those that evaluate isHoverAvailable to false
		const isHoverAvailable = window.matchMedia('(hover)').matches;
		const shouldUseNativeMode = isHoverAvailable && isChrome;
		const recorderMode = shouldUseNativeMode ? 'native' : 'html';
		// this call is not in the product fruits documentation but was given to us through contact with product fruits directly
		window.productFruits.api.feedback.changeRecorderMode(recorderMode);
	});
};

export const reset = () => {
	productFruits.safeExec(() => {
		window?.productFruits?.services?.destroy();
	});
};
