export default {
	servicePath: 'users',
	modelName: 'User',
	instanceDefaults: {
		email: '',
		password: ''
	},
	setupInstance(data, { models, defineSingleAssociation, defineManyAssociation }) {
		defineSingleAssociation(data, 'profile', models.api.Profile);
		defineManyAssociation(data, 'activeLicenses', models.api.License);
		defineManyAssociation(data, 'pendingLicenses', models.api.License);
		defineManyAssociation(data, 'deactivatedLicenses', models.api.License);
		return data;
	}
};
